import Image from "next/image";
import gif from "./loader-animation.gif";

/**
 * This loader takes 100% of the screen space and centers the loader.
 */
export default function FullScreenLoader() {
  return (
    <div
      className="fixed inset-0 z-50 m-0 flex select-none items-center
        justify-center"
    >
      <Image
        src={gif}
        role="presentation"
        alt="Loading..."
        width={150}
        height={177}
        priority
        unoptimized
      />
    </div>
  );
}

import React from "react";
import gif from "./loader-animation.gif";
import Image from "next/image";

/**
 * This loader doesn't have any styling and is just the raw animation.
 */
export default function RawLoader() {
  return (
    <Image
      src={gif}
      role="presentation"
      alt="Loading..."
      width={150}
      height={177}
      priority
      unoptimized
    />
  );
}
